@import url("//fast.fonts.net/t/1.css?apiType=css&projectid=832196c4-86bd-450e-ba18-2fb3cf7bd85c");
@font-face {
  font-family: "Helvetica Neue LT W05_45 Light";
  src: url("./fonts/f9c5199e-a996-4c08-9042-1eb845bb7495.woff2") format("woff2"),
    url("./fonts/2a34f1f8-d701-4949-b12d-133c1c2636eb.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_55 Roman";
  src: url("./fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"),
    url("./fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_65 Medium";
  src: url("./fonts/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"),
    url("./fonts/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W01_75 Bold";
  src: url("./fonts/1f4274cd-2674-481e-9023-69e33ffca161.woff2") format("woff2"),
    url("./fonts/5e8d493c-be01-44b9-962e-504a5cf10dd8.woff") format("woff");
}
